.label-file {
    cursor: pointer;

}

.input2 {
    color: #000000;
    font-weight: bold;
    cursor: pointer;

}

.label-file:hover {
    color: #000000;
}

.input-file {
    display: none;
}

.drop-area.drag-over {
    border: none;
}

.drop-area.file-uploaded {
    border: none;
}

.drop-zone {
    padding: 20px;
    text-align: center;
    margin-top: 10px;
}

.drop-area {
    border: 2px dashed #ccc;
    border-radius: 4px;
    background-color: rgba(160, 160, 160, 0.114);
}

.file-name {
    padding: 6px;
}